exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-thanks-js": () => import("./../../../src/pages/contact/thanks.js" /* webpackChunkName: "component---src-pages-contact-thanks-js" */),
  "component---src-templates-about-page-js": () => import("./../../../src/templates/about-page.js" /* webpackChunkName: "component---src-templates-about-page-js" */),
  "component---src-templates-contact-page-js": () => import("./../../../src/templates/contact-page.js" /* webpackChunkName: "component---src-templates-contact-page-js" */),
  "component---src-templates-craft-objects-page-js": () => import("./../../../src/templates/craft-objects-page.js" /* webpackChunkName: "component---src-templates-craft-objects-page-js" */),
  "component---src-templates-craft-objects-sub-page-js": () => import("./../../../src/templates/craft-objects-sub-page.js" /* webpackChunkName: "component---src-templates-craft-objects-sub-page-js" */),
  "component---src-templates-furniture-page-js": () => import("./../../../src/templates/furniture-page.js" /* webpackChunkName: "component---src-templates-furniture-page-js" */),
  "component---src-templates-furniture-sub-page-js": () => import("./../../../src/templates/furniture-sub-page.js" /* webpackChunkName: "component---src-templates-furniture-sub-page-js" */),
  "component---src-templates-index-page-js": () => import("./../../../src/templates/index-page.js" /* webpackChunkName: "component---src-templates-index-page-js" */),
  "component---src-templates-sculpture-page-js": () => import("./../../../src/templates/sculpture-page.js" /* webpackChunkName: "component---src-templates-sculpture-page-js" */),
  "component---src-templates-sculpture-sub-page-js": () => import("./../../../src/templates/sculpture-sub-page.js" /* webpackChunkName: "component---src-templates-sculpture-sub-page-js" */),
  "component---src-templates-sketches-page-js": () => import("./../../../src/templates/sketches-page.js" /* webpackChunkName: "component---src-templates-sketches-page-js" */),
  "component---src-templates-sketches-sub-page-js": () => import("./../../../src/templates/sketches-sub-page.js" /* webpackChunkName: "component---src-templates-sketches-sub-page-js" */),
  "component---src-templates-stitchscapes-page-js": () => import("./../../../src/templates/stitchscapes-page.js" /* webpackChunkName: "component---src-templates-stitchscapes-page-js" */),
  "component---src-templates-stitchscapes-sub-page-js": () => import("./../../../src/templates/stitchscapes-sub-page.js" /* webpackChunkName: "component---src-templates-stitchscapes-sub-page-js" */)
}

